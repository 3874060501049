/* Variables */
:root {
    /* Colors */
    --main-orange-color: #d26e4b;
}

.mobile-menu-dropdown-trigger:hover {
    color: var(--main-orange-color);
}

.btn-primary.btn-outline:active, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:hover{
    background-color: var(--main-orange-color);
}

.shop-sidebar .btn-outline:not(:hover) {
    color: var(--main-orange-color);
}

.btn-primary.btn-outline {
    color: var(--main-orange-color);
    border-color: var(--main-orange-color);
}

.header-search.hs-simple input.form-control {
    border-color: var(--main-orange-color);
}

.cart-dropdown.type2 .cart-count {
    background-color: var(--main-orange-color);
}


.product.cart-full .btn-cart:active,
.product.cart-full .btn-cart:focus,
.product.cart-full .btn-cart:hover,
.product.product-with-qty .product-details .btn-cart:active,
.product.product-with-qty .product-details .btn-cart:focus,
.product.product-with-qty .product-details .btn-cart:hover {
    background-color: var(--main-orange-color);
    border-color: var(--main-orange-color);
}

.product.product-with-qty button:hover {
    background-color: var(--main-orange-color);
}

/* Whatsapp button */
.float {
    position: fixed;
    width: 80px;
    height: 80px;
    bottom: 100px;
    right: 30px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 50px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.my-float {
    margin-top: 16px;
}

/* Loading */


#loading {
    display: none;
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.5;
    background: #000 url('../../img/loading.gif') no-repeat;
    background-position: center center;
    background-size: 5%;
}

/* When the body has the loading class, we turn
       the scrollbar off with overflow:hidden */
body.is_loading {
    overflow: hidden;
}

/* Anytime the body has the loading class, our
       modal element will be visible */
body.is_loading #loading {
    display: block;
}




/* 
+
+
======================================== Medias querys ========================================
+
+
*/

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .float {
        width: 60px;
        height: 60px;
        bottom: 70px;
        right: 20px;
        text-align: center;
        font-size: 30px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}